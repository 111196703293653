import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController
  ) { }

  async alert(msg){
    const alert = await this.alertController.create({
      header: 'Ok!',
      // subHeader: 'aaa',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async success(msg){
    const alert = await this.alertController.create({
      header: 'Tudo certo!',
      // subHeader: 'aaa',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async error(msg) {
    const alert = await this.alertController.create({
      header: 'Algo deu errado :(',
      // subHeader: 'aaa',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }


  async prompt(header, inputs) {
    return new Promise(async (resolve,reject)=>{
      const alert = await this.alertController.create({
        header: header,
        inputs: inputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              reject();
            }
          }, {
            text: 'OK',
            handler: (e) => {
              resolve(e);
            }
          }
        ]
      })
      await alert.present();
    });

  }

  async toast(msg) {
    const toast = await this.toastController.create({
      // position: 'top',
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  async successToast(msg) {
    const toast = await this.toastController.create({
      color:'success',
      // position: 'top',
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastController.create({
      color:'danger',
      // position: 'top',
      message: msg,
      duration: 2000
    });
    toast.present();
  }
}
